<template>
    <!-- Document Card -->
    <CCard class="card-document">
        <!-- Header -->
        <CCardHeader :class="readClass">
            <div class="d-flex">
                <div class="flex-grow-1 clickable" style="line-height:1.25" @click="toDetail(itemProp)">
                    <span>{{ itemProp.sender }}</span><br/>
                    <span class="small">{{ itemProp.category }}</span>
                </div>
                <div class="flex-grow-0 ml-10 text-right">
                    <div class="d-flex">
                        <div class="flex-grow-0">
                            <CBadge :color="documentBadge.color" shape="pill">{{ documentBadge.label }}</CBadge>
                        </div>
                        <div class="flex-grow-0 ml-2">
                            <font-awesome-icon v-c-tooltip="'Ubah status favorit'" class="clickable" @click="setFavorite(itemProp)" icon="star" :color="(itemProp.is_favorite)?'yellow':''"/>
                        </div>
                        <div class="flex-grow-0 ml-2">
                            <font-awesome-icon class="clickable" icon="eye" 
                                v-c-tooltip="{
                                    content: (itemProp.description == null) ? 'Tidak ada ringkasan' : itemProp.description,
                                    placement: 'bottom-end'
                                }"
                            />
                        </div>
                        <div v-if="isTakahProp === true" class="flex-grow-0 ml-2 mr-1">
                            <CDropdown class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
                                <template #toggler>
                                    <font-awesome-icon class="clickable" icon="ellipsis-v"/>
                                </template>

                                <CDropdownItem class="mt-2" @click="deleteTakahFiles(itemProp)">
                                    <div class="d-flex w-100">
                                        <div class="flex-grow-1">
                                            <div class="small">
                                                <b>Hapus</b>
                                            </div>
                                        </div>
                                    </div>
                                </CDropdownItem>
                            </CDropdown>
                            
                        </div>
                    </div>
                </div>
            </div>
        </CCardHeader>
        <!-- Body -->
        <CCardBody :class="`normal-doc-${readClass} clickable`" @click="toDetail(itemProp)" style="height:125px">
            <div>
                <div><b>{{ itemProp.title }}</b></div>
                <div class="small">{{ itemProp.doc_no }}</div>
                <div class="small">{{ documentTime }}</div>
                <div class="small">{{ itemProp.agenda_no }}</div>
            </div>
        </CCardBody>
        <!-- Footer -->
        <CCardFooter>
            <CRow>
                <!-- Comments Counter -->
                <CCol col="4" class="text-left">
                    <CBadge color="warning" shape="pill" class="badge-comment">{{ itemProp.comments_count }}</CBadge>
                    <font-awesome-icon icon="comment-alt" class=""/>
                    Diskusi
                </CCol>
                <!-- Disposition Action -->
                <CCol
                    v-if="dispositionFeature"
                    col="4" class="clickable text-center"
                    @click="showDispositionForm(itemProp)">
                    <font-awesome-icon icon="share" class=""/>
                    Disposisi
                </CCol>
                <CCol v-else col="4"></CCol>
                <!-- Approval Status -->
                <CCol v-if="documentStatus != null" col="4" class="text-right">
                    <font-awesome-icon icon="circle" :color="documentStatus.color" />
                    {{ documentStatus.label }}
                </CCol>
            </CRow>
        </CCardFooter>
    </CCard>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        itemProp: {
            type: Object,
            required: true
        },
        isTakahProp: {
            type: Boolean,
            required: false
        },
        takahIdProp: {
            type: Number,
            required: false
        }
    },
    computed: {
        ...mapState('auth', {
            currentUserId: 'user_id',
			currentFullname: 'fullname',
            currentPosition: 'position',
			currentEmail: 'email'
		}),
        documentTime() {
            let docTime =  this.itemProp.send_at
            return this.$moment(docTime).format("Y-MM-DD")
        },
        documentBadge() {
            let label = this.itemProp.badge
            let color = 'warning'
            if (label == 'Disposisi') {
                color = 'info'
            } else if (label == 'Tag' || label == 'Shared' || label == 'Siap Kirim') {
                color = 'secondary'
            }

            if(this.itemProp.type == 'Nota Dinas') {
                color = 'success'
                if(label == 'Siap Kirim') {
                    color = 'secondary'
                }
            }

            let badgeProp = { label, color }
            return badgeProp
        },
        documentStatus() {
            if(this.itemProp.status == "Baru") {
                return { color : 'orange', label : this.itemProp.status}
            }
            if(this.itemProp.status == "Disetujui") {
                return { color : 'green', label : this.itemProp.status}
            }
            if(this.itemProp.status == "Peninjauan") {
                return { color : 'dark', label : this.itemProp.status}
            }
            if(this.itemProp.status == "Ditolak") {
                return { color : 'red', label : this.itemProp.status}
            }
            if(this.itemProp.status == "Direvisi") {
                return { color : 'red', label : this.itemProp.status}
            }
            return null;
        },
        readClass() {
            if (this.itemProp.badge == 'Shared Satker' ) {
                return 'gray'
            } else if (this.itemProp.is_read) {
                return 'read'
            }
            return 'unread'
        },
	},  
    methods: {
		setFavorite (documentProp) {
			let docId = documentProp.doc_id
			let favoriteState = !documentProp.is_favorite
			this.$store.dispatch('document/setFavorite', {
				doc_id: docId,
				favorite: favoriteState
			}).then(res => {
				if (res) {
					// Update changed is_favorite value in collection data
                    this.itemProp.is_favorite = favoriteState
					let favoriteAction = (favoriteState) ? 'menambahkan' : 'menghapus'
					this.$toasted.success('Berhasil '+ favoriteAction +' surat favorit').goAway(3000)
				} else {
					this.$toasted.info('Terjadi kesalahan').goAway(3000)
				}
			}).catch(error => {
				this.$toasted.info(error).goAway(3000)
			})
		},
		toDetail (documentProp) {
            let isForceAccess = localStorage.getItem('isForceAccess')
            if(isForceAccess != null) localStorage.removeItem('isForceAccess')
            localStorage.setItem('isForceAccess', false)

            documentProp.is_read = true
            let docId = documentProp.doc_id
            let routePrefix = this.$route.path
			this.$router.push(routePrefix +'/detail/'+ docId)
        },
		showDispositionForm (documentProp) {
            if (documentProp != null) this.$emit('show-disposition-form', documentProp)
		},
        showSummary (item) {

        },

        // Remove Takah
        deleteTakahFiles (documentProp) {
            if (this.isTakahProp == true && documentProp.takah != null) {
                this.$confirm({
                    auth: false,
                    message: "Hapus data terpilih?",
                    button: {
                        no: 'Tidak',
                        yes: 'Ya'
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$store.dispatch({
                                type: 'takah/destroy_files',
                                formInput: {
                                    docId: [documentProp.doc_id],
                                    takahId: documentProp.takah.takah_id
                                }
                            }).then( res => {
                                console.log("Result: ", res)
                                this.$emit('success-remove-takah', true)
                                this.$toasted.success("Sukses menghapus data!").goAway(3000)
                            }).catch( err => {
                                console.log("Error: ", err.response)
                                let errorMsg = "Gagal menghapus data!"
                                if (err.response.status == 400) errorMsg = "Akses terbatas untuk aksi yang dipilih!"
                                this.$toasted.error(errorMsg).goAway(3000)
                            })
                        }
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .card-body.normal-doc-unread {
        background: linear-gradient(90deg, #ae1c1c, hsla(0,0%,100%,0%)), url('../../assets/img/document/d3.jpeg');
		background-size: cover;
		background-position: center;
		cursor: pointer;
    }

    .card-body.normal-doc-read {
        background: linear-gradient(90deg, #e16767, hsla(0,0%,100%,0%)), url('../../assets/img/document/d3.jpeg');
		background-size: cover;
		background-position: center;
		cursor: pointer;

    }
    .card-body.normal-doc-gray {
        background: linear-gradient(90deg, #808080  , hsla(0,0%,100%,0%)), url('../../assets/img/document/d3.jpeg');
		background-size: cover;
		background-position: center;
		cursor: pointer;

    }
</style>