<template>
    <div class="m-0 p-0">
        <div class="d-flex" style="margin-bottom:10px!important">
            <div class="flex-grow-1">
                <span class="mr-1">
                    <CButton
                        color="danger"
                        variant="outline"
                        shape="pill"
                        size="sm">
                        Urutkan: {{ sorts.active.label }}
                    </CButton>
                </span>
                <span class="mr-1" v-for="(item, index) in filters.activeFilters" :key="index">
                    <CButton
                        v-if="item.value && item.value != ''"
                        @click="removeBadgeFilter(item.target)"
                        color="danger"
                        variant="outline"
                        shape="pill"
                        size="sm">
                        {{ item.label }}: {{ item.value }}
                        &nbsp;<CBadge><font-awesome-icon icon="times"/></CBadge>
                    </CButton>
                </span>
            </div>
            <div class="flex-grow-0 ml-10">
                <div class="d-inline-flex justify-center">
                    <div>
                        <CButton v-if="!filters.showFilter" @click="filters.showFilter = !filters.showFilter" class="btn-outline-danger btn-sm">
                            Filter<font-awesome-icon icon="filter"/>
                        </CButton>
                        <CButton v-else @click="filters.showFilter = !filters.showFilter" class="btn-sm">
                            <font-awesome-icon icon="times"/>
                        </CButton>
                    </div>
                </div>
            </div>
        </div>
        <CCollapse :show="filters.showFilter" :duration="400">
            <CCard class="p-0">
                <CCardBody>
                    <CRow class="filter-container">
                        <CCol col="2" class="border-right">
                            <div class="form-input">
                                <label class="label">Tanggal Awal</label>
                                <date-picker 
                                    v-model="filters.input.dateStart" 
                                    :default-value="filters.input.dateStart"
                                    placeholder="YYYY-MM-DD"
                                    value-type="format"
                                    type="date"
                                    class="w-100">
                                </date-picker>
                            </div>
                            <div class="form-input">
                                <label class="label">Tanggal Akhir</label>
                                <date-picker 
                                    v-model="filters.input.dateFinish" 
                                    :default-value="filters.input.dateFinish"
                                    placeholder="YYYY-MM-DD"
                                    value-type="format"
                                    type="date"
                                    class="w-100">
                                </date-picker>
                            </div>
                        </CCol>
                        <CCol col="4">
                            <CInput
                                v-model="filters.input.title"
                                label="Perihal Surat"
                                placeholder="Contoh: Surat Pemberitahuan"/>
                            <CInput
                                v-model="filters.input.docNo"
                                label="Nomor Surat"
                                placeholder="00/XX/000"/>
                            <div class="mb-2">
                                <label class="typo__label">Kategori Surat</label>
                                <multiselect
                                    v-model="filters.input.category"
                                    :allow-empty="true"
                                    :close-on-select="true"
                                    :maxHeight="200"
                                    :multiple="false"
                                    :options="filters.categoryOptions"
                                    deselectLabel=""
                                    openDirection="bottom"
                                    placeholder="Pilih Kategori Surat"
                                    selectLabel=""
                                    selectedLabel="">
                                </multiselect>
                            </div>
                        </CCol>
                        <CCol col="3">
                            <div class="mb-2">
                                <label class="typo__label">Kelompok Surat</label>
                                <multiselect
                                    v-model="filters.input.badge"
                                    :allow-empty="true"
                                    :close-on-select="true"
                                    :maxHeight="200"
                                    :multiple="false"
                                    :options="filters.badgeOptions"
                                    :preselectFirst="true"
                                    deselectLabel=""
                                    openDirection="bottom"
                                    placeholder="Pilih Kelompok Surat"
                                    selectLabel=""
                                    selectedLabel="">
                                </multiselect>
                            </div>
                            <div class="mb-4">
                                <label class="typo__label">Tipe Surat</label>
                                <multiselect
                                    v-model="filters.input.type"
                                    :allow-empty="true"
                                    :close-on-select="true"
                                    :disabled="filters.disabledProps.type"
                                    :maxHeight="200"
                                    :multiple="false"
                                    :options="filters.typeOptions"
                                    :preselectFirst="true"
                                    deselectLabel=""
                                    openDirection="bottom"
                                    placeholder="Pilih Tipe Surat"
                                    selectLabel=""
                                    selectedLabel="">
                                </multiselect>
                            </div>
                            <div class="mb-2">
                                <label class="typo__label">Sifat Surat</label>
                                <multiselect
                                    v-model="filters.input.trait"
                                    :allow-empty="true"
                                    :close-on-select="true"
                                    :maxHeight="200"
                                    :multiple="false"
                                    :options="filters.traitOptions"
                                    :preselectFirst="true"
                                    deselectLabel=""
                                    openDirection="bottom"
                                    placeholder="Pilih Sifat Surat"
                                    selectLabel=""
                                    selectedLabel="">
                                </multiselect>
                            </div>
                        </CCol>
                        <CCol col="3">
                            <div class="mb-2">
                                <label class="typo__label">Level Surat</label>
                                <multiselect
                                    v-model="filters.input.level"
                                    :allow-empty="true"
                                    :close-on-select="true"
                                    :maxHeight="200"
                                    :multiple="false"
                                    :options="filters.levelOptions"
                                    :preselectFirst="true"
                                    deselectLabel=""
                                    openDirection="bottom"
                                    placeholder="Pilih Level Surat"
                                    selectLabel=""
                                    selectedLabel="">
                                </multiselect>
                            </div>
                            <div class="d-flex flex-column mb-3">
                                <div>
                                    <label class="typo__label">Flag</label>
                                </div>
                                <div>
                                    <CInputCheckbox
                                        label="Surat Diterbitkan"
                                        :inline="false"
                                        :checked="filters.input.isPublish"
                                        @update:checked="inputCheckPublish($event)"/>
                                </div>
                                <div>
                                    <CInputCheckbox
                                        label="Surat Favorit"
                                        :inline="false"
                                        :checked="filters.input.isFavorite"
                                        :disabled="filters.disabledProps.isFavorite"
                                        @update:checked="inputCheckFavorite($event)"/>
                                </div>
                            </div>
                            <div class="mt-2">
                                <label class="typo__label">Urutkan</label>
                                <multiselect
                                    v-model="sorts.input.selectedSort"
                                    :allow-empty="true"
                                    :close-on-select="true"
                                    :maxHeight="200"
                                    :multiple="false"
                                    :options="sorts.sortOptions"
                                    :preselectFirst="true"
                                    deselectLabel=""
                                    label="label"
                                    openDirection="bottom"
                                    placeholder="Pilih Level Surat"
                                    selectLabel=""
                                    selectedLabel=""
                                    trackBy="label">
                                </multiselect>
                            </div>
                        </CCol>
                    </CRow>
                    <div class="d-flex justify-end">
                        <CButton @click="resetFilters" color="warning" class="btn-sm mr-1" :disabled="loading">
                            <font-awesome-icon icon="undo"/>
                            Reset
                        </CButton>
                        <CButton @click="applyFilters" color="success" class="btn-sm" :disabled="loading">
                            <font-awesome-icon v-if="loading" icon="spinner" spin/>
                            <font-awesome-icon v-else icon="check-circle"/>
                            Cari
                        </CButton>
                    </div>
                </CCardBody>
            </CCard>
        </CCollapse>
    </div>
</template>

<script>
export default {
    props: {
        showProp: {
            type: Boolean,
            default: false
        },
        docTypeProp: {
            type: String,
            default: 'All'
        },
        categoryOptionsProp: {
            type: Array,
            default: function() {
                return []
            }
        },
        archiveStateProp: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
			filters: {
                showFilter: false,
                activeFilters: [
                    {
                        label: "Tipe",
                        target: "type",
                        value: "",
                        disabled: false
                    },{
                        label: "Kelompok",
                        target: "badge",
                        value: "Semua"
                    },{
                        label: "Sifat",
                        target: "trait",
                        value: "Semua"
                    },{
                        label: "Level",
                        target: "level",
                        value: "Semua"
                    },{
                        label: "Judul",
                        target: "title",
                        value: ""
                    },{
                        label: "Nomor",
                        target: "docNo",
                        value: ""
                    },{
                        label: "Tanggal Awal",
                        target: "dateStart",
                        value: ""
                    },{
                        label: "Tanggal Akhir",
                        target: "dateFinish",
                        value: ""
                    },{
                        label: "Nomor",
                        target: "docNo",
                        value: ""
                    },{
                        label: "Tanggal Awal",
                        target: "dateStart",
                        value: ""
                    },{
                        label: "Tanggal Akhir",
                        target: "dateFinish",
                        value: ""
                    },{
                        label: "Kategori",
                        target: "category",
                        value: ""
                    },{
                        label: "Favorit",
                        target: "isFavorite",
                        value: ""
                    },{
                        label: "Diterbitkan",
                        target: "isPublish",
                        value: ""
                    }
                ],
                input: {
                    title: "",
                    docNo: "",
                    dateStart: "",
                    dateFinish: "",
                    category: "",
                    type: "",
                    trait: "Semua",
                    badge: "Semua",
                    level: "Semua",
                    isFavorite: false,
                    isPublish: false,
                    isArchive: null,
                    isDone: null
                },
                default: {
                    title: "",
                    docNo: "",
                    dateStart: "",
                    dateFinish: "",
                    category: "",
                    type: "Semua",
                    trait: "Semua",
                    badge: "Semua",
                    level: "Semua",
                    isFavorite: false,
                    isPublish: false,
                    isArchive: null,
                    isDone: null
                },
                disabledProps: [{
                    type: false,
                    isFavorite: false,
                    isArchive: null
                }],
                badgeOptions: ['Semua', 'Disposisi', 'Persetujuan', 'Tag'],
                categoryOptions: [],
                levelOptions: ['Semua', 'Biasa', 'Kilat', 'Prioritas'],
                traitOptions: ['Semua', 'Biasa', 'Rahasia', 'Sangat rahasia'],
                typeOptions: ['Semua', 'Masuk', 'Keluar', 'Nota Dinas'],
            },
            sorts: {
                active: {
                    label: 'Tanggal Buat (Terbaru)',
                    value: {
                        orderBy: 'created_at',
                        sort: 'desc'
                    }
                },
                input: {
                    selectedSort: {
                        label: 'Tanggal Buat (Terbaru)',
                        value: {
                            orderBy: 'created_at',
                            sort: 'desc'
                        }
                    },
                    orderBy: "created_at",
                    sort: "desc"
                },
                default: {
                    selectedSort: {
                        label: 'Tanggal Buat (Terbaru)',
                        value: {
                            orderBy: 'created_at',
                            sort: 'desc'
                        }
                    },
                    orderBy: "created_at",
                    sort: "desc"
                },
                sortOptions: [{
                    label: 'Tanggal Buat (Terbaru)',
                    value: {
                        orderBy: 'created_at',
                        sort: 'desc'
                    }
                }, {
                    label: 'Tanggal Buat (Terlama)',
                    value: {
                        orderBy: 'created_at',
                        sort: 'asc'
                    }
                }, {
                    label: 'Perihal (A - Z)',
                    value: {
                        orderBy: 'title',
                        sort: 'asc'
                    }
                }, {
                    label: 'Perihal (Z - A)',
                    value: {
                        orderBy: 'title',
                        sort: 'desc'
                    }
                }, {
                    label: 'Kategori (A - Z)',
                    value: {
                        orderBy: 'category',
                        sort: 'asc'
                    }
                }, {
                    label: 'Kategori (Z - A)',
                    value: {
                        orderBy: 'category',
                        sort: 'desc'
                    }
                }]
            },
			loading: false
        }
    },
    watch: {
        categoryOptionsProp: {
            handler: function (newValue, oldValue) {
                let items = []
                newValue.forEach((item) => {
                    if (item.label) {
                        items.push(item.label)
                    } else {
                        items.push(item)
                    }
                })
				this.filters.categoryOptions = items
            },
            deep: true,
            imediate: true
		},
    },
    computed: {
		todayDate () {
            return this.$moment(new Date()).add(1, 'd').format("Y-MM-DD")
        },
        yesterdayDate () {
            return this.$moment(new Date()).add(-29, 'd').format("Y-MM-DD")
        }
    },
    mounted () {
        let docType = (this.docTypeProp == 'All' || this.docTypeProp == 'Favorite') ? 'Semua' : this.docTypeProp
        this.filters.input.type = docType
        this.filters.default.type = docType
        this.filters.activeFilters[0].value = docType
        this.filters.disabledProps.type = !(this.docTypeProp == 'All' || this.docTypeProp == 'Favorite')
        if (this.docTypeProp == 'Favorite') {
            this.filters.input.isFavorite = true
            this.filters.default.isFavorite = true
            this.filters.disabledProps.isFavorite = true
        }

        if (this.archiveStateProp === true) {
            this.filters.input.isDone = true
            this.filters.default.isDone = true
        } else {
                this.filters.input.isDone = false
                this.filters.default.isDone = false

        }
    },
    methods: {
        applyFilters () {
            // Set sort configurations
            let selectedSort = this.sorts.input.selectedSort
            this.sorts.input.orderBy = selectedSort.value.orderBy
            this.sorts.input.sort = selectedSort.value.sort

            // Send params into parent
            let params = {
                filters: this.filters.input,
                sorts: this.sorts.input
            }
            this.$emit('apply-advance-filter', params)
        },
        resetFilters () {
            this.filters.input = Object.assign({}, this.filters.default)
            this.sorts.input = Object.assign({}, this.sorts.default)
        },
        refreshFilterBadges () {
            this.sorts.active = this.sorts.input.selectedSort
            this.filters.activeFilters.forEach( item => {
                if (item.target == "isFavorite") {
                    let isFav = this.filters.input[item.target]
                    item.value = (isFav) ? 'Ya' : ''
                } else {
                    item.value = this.filters.input[item.target]
                }
            })
        },
		removeBadgeFilter (target) {
			this.filters.input[target] = ''
			this.applyFilters()
		},
		inputCheckFavorite (value) {
			this.filters.input.isFavorite = value
		},
		inputCheckPublish (value) {
			this.filters.input.isPublish = value
		},
    }
}
</script>

<style lang="scss" scoped>
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
.filter-container {
    div.form-input {
        margin-bottom: 1rem;
    }
}
.border-right {
	border-right: solid black 1px;
}
</style>